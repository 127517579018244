import React from 'react';
import Link from 'gatsby-link';

const NotFoundPage = () => (
  <div>
    <h1>NOT FOUND</h1>
    <h3>You just hit a route that doesn&#39;t exist :(</h3>
    <h3><Link to="/" >
      Home
    </Link></h3>
  </div>
);

export default NotFoundPage;
